export default{
  computed:{
    isMobile(){
      return this.$store.state.windowWidth <= 992 ? true : false
    },
  },
  methods:{
      absoluteUrl(path = ""){
          return this.$store.state.domain+path
      },
      calculateDeliveryTime(time){
          return time > 24
             ?  `${time/24} Days`
             : `${time} Hrs`
      },
      scrollToView(elID){
          if(!elID){return}
          const yOffset = -200;
          const element = document.getElementById(elID);
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
      },
      preText(content) {
          if(!content) return;
          const words = content.replace(/\n/g, ' <br>').split(' ');
          const formattedWords = [];
          words.forEach(word => {
            if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
              formattedWords.push(`<a href="${word}" class="text-success" target="_blank"> ${word} </a>`);
            } else {
              formattedWords.push(word)
            }
          });
          return formattedWords.join(' ')
      },
      isValidIMEI(imei) {
        const regex = /^\d{15}$/;
        return regex.test(imei);
      },
      statusBadge(status){
        if(!status) return;
        let codes = { active : '#0ec569',  past_due  : '#ff0002', reviewed : '#41d051', awaiting_payment: '#FFA500',
          suspended: '#446ee7', paid: '#0ec569', succeeded: '#0ec569',  not_paid : '#000000', rejected  : '#ff0002',
          draft: '#f672a7', pending: '#EA5455', approved: '#0ec569', in_progress: '#3498db', funded: '#4CAF50',
          repaired: '#008000', repaired_collected: '#4CAF50', completed: '#008000', unlocked: '#1E90FF', responded: '#1E90FF',
          canceled: '#FF0000', waiting_for_parts: '#FFA500', waiting_on_customer: '#FFA500', warranty_repair: '#800080',
        }
        return `<span style="background:${codes[status.toLowerCase()]}24; color:${codes[status]}; padding:0px 5px 2px 5px;line-height:1; border-radius:4px;">${status.replace(/_/g, ' ')}</span>`
      },
      alertSuccess(text){
        this.$store.dispatch("alertSuccess", text)
      },
      alertError(text){
        this.$store.dispatch("alertError", text)
      },
      confirmDelete(option){
          return new Promise((resolve, reject) => {
            Swal.fire({
              title: option.title || "Are you sure?",
              html: option.text,
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: "#f1556c",
              cancelButtonColor: "#111",
              confirmButtonText: "Delete it!",
            }).then((result) => {
              resolve(result)
            }).catch((error) => { reject(error) });
          })
      },
      confirmAction(option){
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: option.title || "Confirm action!",
            html: option.text,
            // icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#1bae70",
            cancelButtonColor: "#111",
            confirmButtonText: "Yes, Proceed!",
          }).then((result) => {
            resolve(result)
          }).catch((error) => { reject(error) });
        })
      },
      serverReceipt(payload) {
        return new Promise((resolve, reject) => {
          this.$http.post(`${payload.url}`, payload.data,
          { responseType: 'arraybuffer' })
          .then((response) => {
            resolve(response)
            if(response.status == 200){
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
            }
          }).catch((error) => { reject(error) })
        })
      },
      loadStripe() {
        return new Promise((resolve) => {
          if(document.getElementById("StripeScript")){return resolve()}
          const script = document.createElement('script');
          script.src = `https://js.stripe.com/v3/`;
          script.async = true;
          script.id = "StripeScript"
          script.onreadystatechange = script.onload = function () {
            if (!script.readyState || /loaded|complete/.test(script.readyState)) {
              setTimeout(function () {
                resolve()
              }, 500)
            }
          }
          document.head.appendChild(script);
        })
      },
  },
}
