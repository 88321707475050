
import axios from "@/plugins/axios.js"
import { useToast } from 'vue-toastification'
const toast = useToast()

const actions = {
  alertSuccess({}, message) {
      toast.success(message)
  },
  alertError({}, message) {
      toast.error(message)
  },
  changeLoaderValue({ commit }, val = false) {
    commit("TOGGLE_LOADER", val)
  },
  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.domain}/api/logout`)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  universalFetch({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(action)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  fetchActiveUser({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
      .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/countries`)
      .then((response) => {
        if(response.data.success){
            commit('SET_COUNTRIES', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCoverages({ commit, rootState }) {
    if(rootState.coverages.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/coverages`)
        .then((response) => {
          if(response.data.success){
            commit('SET_COVERAGES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchDevices({ commit, rootState }) {
    if(rootState.devices.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/devices`)
        .then((response) => {
          if(response.data.success){
            commit('SET_DEVICES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchSubscriptions({ commit, rootState}) {
    if(rootState.subscriptions.length) return
    return new Promise((resolve, reject) => {
      axios.get(`/subscriptions`)
        .then((response) => {
          if(response.data.success){
            commit('SET_SUBSCRIPTIONS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchAppointments({ commit, rootState}) {
    if(rootState.appointments.length) return
    return new Promise((resolve, reject) => {
      axios.get(`/appointments`)
        .then((response) => {
          if(response.data.success){
            commit('SET_APPOINTMENT', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchItems({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      if(rootState.items.length) resolve()
      axios.get("/items")
        .then((response) => {
          if(response.data.success){
            commit('SET_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchClaims({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      if(rootState.claims.length) resolve()
      axios.get("/claims")
        .then((response) => {
          if(response.data.success){
            commit('SET_CLAIMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  getClientCountry({commit}, val = false){
    fetch('https://api.ipregistry.co/?key=tryout')
      .then(function (response) {
          return response.json();
      })
      .then(function (payload) {
        commit('SET_COUNTRY', payload.location.country);
      });
  },
}

export default actions
