import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Electronic devices insurance company",
        appEmail: "support@morisoplus.com"
    },
    scrollY: 0,
    windowWidth: window.innerWidth,
    countries: countries,
    coupons : [],
    coverages: [],
    devices:[],
    subscriptions:[],
    appointments:[],
    items:[],
    claims:[]
}

export default state
