import { getUserStorable } from '@/helpers/utils'

const mutations = {
  TOGGLE_LOADER(state, loader) {
    state.isLoading = loader;
  },
  UPDATE_WINDOW_SCROLL_Y(state) {
    state.scrollY = window.scrollY;
  },
  UPDATE_WINDOW_WIDTH(state) {
    state.windowWidth = window.innerWidth
  },
  LOGOUT_USER(state) {
    localStorage.removeItem(state.localUserKey);
    state.AppActiveUser = {};
    location.replace(`${state.domain}/logout`)
  },
  UPDATE_USER_INFO(state, user) {
    if(!user.id){ localStorage.removeItem(state.localUserKey); return};
    state.AppActiveUser 
        ? Object.assign(state.AppActiveUser, user)
        : state.AppActiveUser = {...user};

    const get_localUser = localStorage.getItem(state.localUserKey);
    const storedData = JSON.parse(unescape(atob(get_localUser)));
    storedData.user = getUserStorable(user);
    localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
  },
  SET_AUTH_TOKEN(state, data){
    const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
    const config = {
        token: data.accessToken,
        tokenExpirationDate: expirationTime.toISOString(),
        user: getUserStorable(data.user),
    }
    const encryptedValue = btoa(escape(JSON.stringify(config)));
    localStorage.setItem(state.localUserKey, encryptedValue);
  },
  UPDATE_NOTIFICATION(state, notification){
      state.activeUser.notification = {...notification};
  },
  SET_COUNTRIES(state, countries){
    state.countries = [...countries];
  },
  SET_COUNTRY(state, country){
    state.country = {...country};
  },

  /************** FOR ITEMS *****************/
  ADD_ITEM(state, item){
    state.items.unshift(item);
  },
  SET_ITEMS(state, items){
    state.items = [...items];
  },
  UPDATE_ITEM(state, item){
    const dataIndex = state.items.findIndex((p) => p.id == item.id)
    Object.assign(state.items[dataIndex], item)
  },
  DELETE_ITEM(state, itemId){
    const ItemIndex = state.items.findIndex((item) => item.id == itemId)
    state.items.splice(ItemIndex, 1);
  },

  /************** FOR CLAIMS *****************/
  ADD_CLAIM(state, claim){
    state.claims.unshift(claim);
  },
  SET_CLAIMS(state, claims){
    state.claims = [...claims];
  },
  UPDATE_CLAIM(state, claim){
    const dataIndex = state.claims.findIndex((p) => p.id == claim.id)
    Object.assign(state.claims[dataIndex], claim)
  },
  DELETE_CLAIM(state, claimId){
    const ItemIndex = state.claims.findIndex((claim) => claim.id == claimId)
    state.claims.splice(ItemIndex, 1);
  },


  SET_COUPONS(state, coupons){
    state.coupons = [...coupons];
  },
  SET_COVERAGES(state, coverages){
    state.coverages = [...coverages];
  },
  SET_DEVICES(state, devices){
    state.devices = [...devices];
  },
  SET_SUBSCRIPTIONS(state, subscriptions){
    state.subscriptions = [...subscriptions]
  },  

  SET_APPOINTMENT(state, appointments){
    state.appointments = [...appointments]
  },  
} 

export default mutations

