const options = {
  truncate(value, limit) {
    if(value.length > limit){
      return value.substring(0, limit)+' ...'
    }else{
      return value
    }
  },
  unmaskMobile(value) {
    if (!value) return null
    return value.replace(/\D/g, '');
  },
  slug(value) {
    if (!value) return null
    return value.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
  },
  textFromSlug(value){
    if (!value) return '';
    let words = value.split('_');
    let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return formattedWords.join(' ');
  },
  capitalize (value) {
    if (!value) return value
    value = value.toString()
    let arr = value.split(" ")
    let capitalized_array = []
    arr.forEach((word) => {
      let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalized_array.push(capitalized)
    })
    return capitalized_array.join(" ");
  },
  time(value, is24HrFormat = false) {
    if(value) {
      const date = new Date(Date.parse(value));
      let hours = date.getHours();
      const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
      if(!is24HrFormat) {
        const time = hours > 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;
        return hours + ':' + min + ' ' + time
      }
      return hours + ':' + min
    }
  },
  format_time(timeString) {
    const [hours, minutes, seconds] = timeString.split(':');
    const formattedTime = new Date();
    formattedTime.setHours(hours, minutes, seconds);

    const options = { hour: 'numeric', minute: 'numeric' };
    const formatted = formattedTime.toLocaleString('en-US', options);
  
    return formatted;
  },
  date_time(value, index = 0) {
    if(!value) return ''
    var options = [
      { weekday:'long', year:'numeric', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { year:'numeric', month:'short', day:'2-digit' }
    ];
    var givenDate  = new Date(value);
    return new Intl.DateTimeFormat('en-US', options[index]).format(givenDate)
  },
  date(value, index = 0) {
    if(!value) return ''
    var options = [
      {year: 'numeric', month: 'long', day: 'numeric' },
      {weekday: 'long', year: '2-digit', month: 'short', day: '2-digit' },
      {month: 'long', day: 'numeric' },
      {month: 'long' }
    ];
    var givenDate  = new Date(value);
    return new Intl.DateTimeFormat('en-US', options[index]).format(givenDate)
  },
  server_date(value) {
    if(!value) return null
    return value.toLocaleDateString('en-US', {day: 'numeric', month: 'short', year: 'numeric'});
  },
  month_year(value) {
    var options = {month: 'short', year: 'numeric'};
    var givenDate  = new Date(value);
    if(!value || givenDate == 'Invalid Date'){return;}
    return givenDate.toLocaleString("en-US", options);
  },
  month(val, showYear = true) {
    val = String(val)
    const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
    if(!showYear) {
      return regx.exec(val)[1];
    }else {
      return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
    }
  },
  csv(value) {
    return value.join(', ')
  },
  filter_tags(value) {
    return value.replace(/<\/?[^>]+(>|$)/g, "")
  },
  ordinalSuffix(number) {
    if (number === 0) {
      return '0';
    }
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const remainder = number % 100;
    return number + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
  },
  monthDayWithSuffix(value){
    if(!value) return;
    value = new Date(value);
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
    return formatter.format(value) + ' ' + this.ordinalSuffix(value.getDate());
  },
  getLTDatesForWeekday(weekday) {
    weekday = parseInt(weekday)
    const today = new Date();
    const currentDay = today.getDay(); // Get today's day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const daysDifference = weekday - currentDay;
    const thisWeekDate = new Date(today.setDate(today.getDate() + daysDifference));
    const lastWeekDate = new Date(today.setDate(thisWeekDate.getDate() - 7))
    return [lastWeekDate, thisWeekDate];
  },
  getWeekdayName(value) {
    value = parseInt(value)
    const weekdays = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (value >= 0 && value <= 6) {
      return weekdays[value];
    }
    return '';
  },
  k_formatter(num) {
    return num > 999 ? (num/1000).toFixed(1) + 'k' : num
  },
  money_format(number) {
    let i = parseFloat(number); if(isNaN(i)) {return;}
    return i.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },
  patchUpdatables(data, filter) {
    let allowed = Object.keys(data);
    const filteredData =  Object.keys(filter || {})
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
          obj[key] = filter[key];
          return obj;
      }, {});
    return JSON.parse(JSON.stringify(filteredData))
  },
  date_ago(utctime, index = 0) {
    const iptDate = new Date(utctime);
    const curDate = new Date();
    const timeD = curDate - iptDate;
    const millisecsPerSec = 1000;
    const millisecsPerMin = 60 * millisecsPerSec;
    const millisecsPerHr = 60 * millisecsPerMin;
    const millisecsPerDay = 24 * millisecsPerHr;

    if (timeD < millisecsPerDay) {
      const daysAgo = Math.floor(timeD / millisecsPerDay);
      const hrsAgo = Math.floor((timeD % millisecsPerDay) / millisecsPerHr);
      const minsAgo = Math.floor((timeD % millisecsPerHr) / millisecsPerMin);
      const secsAgo = Math.floor((timeD % millisecsPerMin) / millisecsPerSec);

      if (daysAgo > 0) {
        return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
      } else if (hrsAgo > 0) {
        return `${hrsAgo} hr${hrsAgo > 1 ? 's' : ''} ago`;
      } else if (minsAgo > 0) {
        return `${minsAgo} min${minsAgo > 1 ? 's' : ''} ago`;
      } else if (secsAgo > 0) {
        return `${secsAgo} sec${secsAgo > 1 ? 's' : ''} ago`;
      } else {
        return "just now";
      }
    } else {
      const options = [
        { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
        { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }
      ];
      return new Intl.DateTimeFormat('en-US', options[index]).format(iptDate)
    }
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$filters = options
    // app.provide('$filters', options)
  }
}