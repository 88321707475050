export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
                path: '/items',
                name: 'items',
                component: () => import('@views/items/ItemList.vue'),
                meta: {
                  title: "Items",
                  authRequired: true,
                },
            },
            {
              path: '/items/:itemId(\\d+)',
              name: 'view-item',
              component: () => import('@views/items/ItemShow.vue'),
              meta: {
                title: "View item",
                authRequired: true,
              },
            },
            {
              path: '/claims',
              name: 'claims',
              component: () => import('@views/claims/ClaimList.vue'),
              meta: {
                title: "Claims",
                authRequired: true,
              },
            },
            {
              path: '/claims/file-new',
              name: 'file-new-claim',
              component: () => import('@views/claims/AddNewClaim.vue'),
              meta: {
                title: "File new claims",
                authRequired: true,
              },
            },
            {
              path: '/claims/:claimId(\\d+)',
              name: 'view-claims',
              component: () => import('@views/claims/ClaimShow.vue'),
              meta: {
                title: "View claim",
                authRequired: true,
              },
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@views/notification/NotificationList.vue'),
                meta: {
                  title: "Notifications",
                  authRequired: true,
                }
            },
            {
              path: '/subscriptions',
              name: 'subscriptions',
              component: () => import('@views/subscriptions/SubscriptionList.vue'),
              meta: {
                title: 'Subscriptions',
                authRequired: true,
              },
            },
            {
              path: '/subscriptions/:subId(\\d+)',
              name: 'view-subscription',
              component: () => import('@views/subscriptions/SubscriptionShow.vue'),
              meta: {
                title: "View Subscription",
                authRequired: true,
              },
            },
            {
              path: '/appointments',
              name: 'appointments',
              component: () => import('@views/appointments/AppointmentList.vue'),
              meta: {
                title: 'Appointments',
                authRequired: true,
              },
            },
            {
              path: '/appointments/:appointId(\\d+)',
              name: 'view-appointment',
              component: () => import('@views/appointments/AppointmentShow.vue'),
              meta: {
                title: "View appointment",
                authRequired: true,
              },
            },
            {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                title: 'Profile',
                authRequired: true,
              },
            },
            {
              path: '/newsletter',
              name: 'newsletter',
              component: () => import('@views/settings/Newsletter.vue'),
              meta: {
                title: 'Newsletter',
                authRequired: true,
              },
            },
            {
              path: '/referrals',
              name: 'referrals',
              component: () => import('@views/settings/Referrals.vue'),
              meta: {
                title: 'Referrals',
                authRequired: true,
              },
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
              path: '/auth',
              name: 'auth',
              component: () => import('@/views/pages/Authenticate.vue'),
              meta:{
                title: "Auto Login"
              }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]